<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-24 09:09:17
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-03-07 16:08:18
 * @Description  : 修改设备名称
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("rename") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="addForm"
        status-icon
        ref="resetRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label-width="0px">
          <el-input
            clearable
            v-model="addForm.name"
            autocomplete="off"
            :placeholder="$t('only_English')"
            @keyup.native="(e) => keyUpForEnglishOnly(e, 0)"
            @blur.native="(e) => checkForEnglishOnlyOnBlur(e)"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editShipmentNameApi } from "@/api/api.js";

export default {
  name: "ChangeShipmentNameDialog",
  data() {
    return {
      addForm: {
        name: "",
      },
      dialogFormVisible: false,
      shipmentId: "",
    };
  },
  methods: {
    showDialog(id, name) {
      this.dialogFormVisible = true;
      this.shipmentId = id;
      this.addForm.name = name;
    },
    handelOk() {
      if (!this.addForm.name) {
        this.$message.error(this.$t("please_enter_shipment_name"));
        return;
      }
      let params = {
        ...this.addForm,
        shipmentId: this.shipmentId,
        timezone: this.timezone,
      };
      editShipmentNameApi(params).then((r) => {
        console.log("=====", r);

        this.$message.success(this.$t("modify_success"));

        this.dialogFormVisible = false;
        this.$emit("refresh");
      });
    },
    keyUpForEnglishOnly(e, maxLength) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z0-9_]/g, "");
      if (maxLength && value.length > maxLength) {
        value = value.substring(0, maxLength);
      }
      e.target.value = value;
      e.target.dispatchEvent(new Event("input"));
    },
    checkForEnglishOnlyOnBlur(e) {
      // 调用相同的逻辑，但不需要处理maxLength
      this.keyUpForEnglishOnly(e, null);
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less"></style>
