<!--
 * @Author       : JiangChao
 * @Date         : 2025-01-02 17:07:50
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-04-02 14:46:33
 * @Description  : 运单进度组件   0:未开始 1：进行中 9：已结束
-->
<template>
  <div class="timeline">
    <!-- 开始 -->
    <div :style="{ width: stepLineWidth }" class="timeline-item">
      <span>{{ this.$t("start") }}</span>
      <div class="dot-line">
        <div
          v-if="shipmentInfo.status == 0"
          class="dot"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>
        <div
          v-if="shipmentInfo.status == 1 || shipmentInfo.status == 9"
          class="dot"
          :style="{ backgroundColor: '#20bb4c' }"
        ></div>

        <div
          class="right-line"
          v-if="shipmentInfo.status == 0"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>
        <div
          class="right-line"
          v-if="shipmentInfo.status == 1 || shipmentInfo.status == 9"
          :style="{ backgroundColor: '#20bb4c' }"
        ></div>
      </div>
      <div class="content">
        <p v-if="shipmentInfo.status == 0">{{ this.$t("not_started") }}</p>
        <p v-if="shipmentInfo.status == 1 || shipmentInfo.status == 9">
          {{ shipmentInfo.startTime | timeAgo }}
        </p>
        <p>
          {{
            shipmentInfo.startTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </p>
      </div>
    </div>
    <!-- 进行中 -->
    <div :style="{ width: stepLineWidth }" class="timeline-item">
      <span>{{ this.$t("tab_on_going") }}</span>
      <div class="dot-line">
        <div
          class="left-line"
          v-if="shipmentInfo.status == 0"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>
        <div
          class="left-line"
          v-if="shipmentInfo.status == 1 || shipmentInfo.status == 9"
          :style="{ backgroundColor: '#20bb4c' }"
        ></div>
        <div
          v-if="shipmentInfo.status == 0"
          class="dot"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>
        <div
          v-if="shipmentInfo.status == 1 || shipmentInfo.status == 9"
          class="dot"
          :style="{ backgroundColor: '#20bb4c' }"
        ></div>
        <div
          class="right-line"
          v-if="shipmentInfo.status == 0"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>
        <div
          class="right-line"
          v-if="shipmentInfo.status == 1 && !shipmentInfo.endTime"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>

        <div
          class="right-line"
          v-if="shipmentInfo.status == 1"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>

        <div
          class="right-line"
          v-if="shipmentInfo.status == 9 && shipmentInfo.endTime"
          :style="{ backgroundColor: '#20bb4c' }"
        ></div>
      </div>
      <div class="content">
        <p
          v-if="
            (shipmentInfo.status == 1 || shipmentInfo.status == 9) &&
            shipmentInfo.recordNum >= 0
          "
        >
          {{ shipmentInfo.recordNum }} {{ $t("records") }}
        </p>

        <p :class="{ 'hidden-when-false': !shipmentInfo.latestDate }">
          {{
            shipmentInfo.latestDate
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </p>
      </div>
    </div>
    <!-- 结束 -->
    <div :style="{ width: stepLineWidth }" class="timeline-item">
      <span>{{ this.$t("end") }}</span>
      <div class="dot-line">
        <div
          class="left-line"
          v-if="shipmentInfo.status == 0"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>

        <div
          class="left-line"
          v-if="shipmentInfo.status == 1 && !shipmentInfo.endTime"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>

        <div
          class="left-line"
          v-if="shipmentInfo.status == 1"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>

        <div
          class="left-line"
          v-if="shipmentInfo.status == 9 && shipmentInfo.endTime"
          :style="{ backgroundColor: '#20bb4c' }"
        ></div>
        <div
          v-if="shipmentInfo.status == 0"
          class="dot"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>
        <div
          v-if="shipmentInfo.status == 1 && !shipmentInfo.endTime"
          class="dot"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>
        <div
          v-if="shipmentInfo.status == 1 && shipmentInfo.endTime"
          class="dot"
          :style="{ backgroundColor: '#9b9b9b' }"
        ></div>
        <div
          v-if="shipmentInfo.status == 9 && shipmentInfo.endTime"
          class="dot"
          :style="{ backgroundColor: '#f5582e' }"
        ></div>
      </div>
      <div class="content">
        <p v-if="shipmentInfo.status == 9 && shipmentInfo.endTime">
          {{ this.$t("ended") }}
        </p>
        <p v-if="shipmentInfo.status == 1 && shipmentInfo.endTime">
          {{ this.$t("will_end") }}
        </p>
        <p :class="shipmentInfo.status == 0 ? 'hidden-when-false' : ''"></p>

        <p :class="{ 'hidden-when-false': !shipmentInfo.endTime }">
          {{
            shipmentInfo.endTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepBox",
  props: {
    shipmentInfo: {
      type: Object,
      default: () => ({
        status: 0,
        startTime: "",
        endTime: "",
        alertNum: 0,
      }),
    },
  },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    // steps() {
    //   // 0:未开始 1：进行中 9：已结束
    //   return [
    //     {
    //       label: this.$t("start"),
    //       status: this.shipmentInfo.status >= 1 ? 1 : 0,
    //       timeField: "startTime",
    //     },
    //     {
    //       label: this.$t("tab_on_going"),
    //       status: this.shipmentInfo.status === 1 ? 1 : 0,
    //       // timeField: "currentTime",
    //     },
    //     {
    //       label: this.$t("end"),
    //       status: this.shipmentInfo.status === 9 ? 1 : 0,
    //       // timeField: "endTime",
    //     },
    //   ];
    // },
    stepLineWidth() {
      // 动态计算线条宽度，这里假设屏幕宽度除以6是一个合适的宽度。
      // 根据实际需求调整计算逻辑。
      return `${window.innerWidth / 8}px`;
    },
  },
  mounted() {
    console.log("stepLineWidth: ", this.stepLineWidth);
  },
};
</script>

<style scoped lang="less">
.timeline {
  display: flex;
  align-items: center;
  margin: 20px 0;

  .timeline-item {
    position: relative;
    text-align: center;
    min-height: 20px;

    span {
      font-weight: bold;
      display: block;
      margin-bottom: 5px;
    }

    .dot-line {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 10px;
      width: 100%;

      .dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #ccc;
        position: relative;
        z-index: 2;
      }

      .left-line,
      .right-line {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        background-color: #ccc;
        width: calc(50% - 10px);
      }

      .left-line {
        left: 0;
      }

      .right-line {
        right: 0;
      }
    }

    .content {
      height: 20px;
      .hidden-when-false {
        opacity: 0; /* 使文本不可见 */
      }
    }
  }
}
</style>
